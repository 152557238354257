<template>
	<div class="relative pb-[66%]">
		<iframe v-if="showVideo" loading="lazy" allow="autoplay" width="100%" height="100%" :src="videoSource" frameborder="0" allowfullscreen class="absolute w-full" />
		<template v-else>
			<goPicture v-if="!showVideo" v-bind="picture" class="absolute top-0 left-0 w-full" />
			<figcaption>
				<div class="text-14 text-white absolute bottom-8 left-24 text-shadow-caption">
					<span>{{ title }}</span>
					<span v-if="duration" class="opacity-70"> ({{ duration }})</span>
				</div>
				<button aria-label="Play video" class="text-goPink hover:text-goPinkDark active:text-goPinkDarker outline-0 cursor-pointer absolute top-6/12 left-6/12 -translate-x-6/12 -translate-y-6/12 border-0 bg-transparent" @click="loadYoutube">
					<svg-icon name="play" class="iconSize-52" />
				</button>
			</figcaption>
		</template>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		image: {
			type: Object,
			default: () => {},
		},
		src: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		duration: {
			type: String,
			default: null
		},
	},
	data() {
		return {
			showVideo: false,
			picture: {},
		};
	},
	computed: {
		videoSource() {
			if (!this.showVideo) return null
			return this.src + "?rel=0&showinfo=0&autoplay=1"
		},
	},
	created() {
		this.picture = Object.assign(this.picture, this.image)
	},
	methods: {
		loadYoutube() {
			this.showVideo = true
		},
	},
};
</script>
